/* Portfolio Section Styling */
#portfolio {
  padding: var(--spacing-large);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* border-radius: var(--border-radius-medium); */
  background-color: var(--secondary-bg-color);
}

#portfolio h1 {
  font-size: var(--font-size-xl);
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: var(--spacing-large);
}

#portfolio h2 {
  font-size: var(--font-size-large);
  color: var(--text-color);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-medium);
}

/* Publications and Awards Containers */
.publications, .awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

/* Individual Publication Styling */
.publication {
  background-color: var(--secondary-bg-color);
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-light);
  margin-bottom: var(--spacing-medium);
  width: 100%;
  text-align: left;
  box-shadow: var(--shadow-medium);
}

.publication p {
  font-size: var(--font-size-base);
  color: var(--muted-text-color);
  margin: var(--spacing-xs) 0;
}

.publication a {
  font-size: var(--font-size-base);
  color: var(--primary-color);
  text-decoration: none;
  margin-top: var(--spacing-small);
}

.publication a:hover {
  text-decoration: underline;
  color: var(--text-color);
}

.note {
  margin-top: var(--spacing-small);
  color: var(--muted-text-color);
  font-size: var(--font-size-small);
}

/* Award Item Styling */
.award-item {
  background-color: var(--secondary-bg-color);
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-medium);
  margin-bottom: var(--spacing-medium);
  width: 100%;
  max-width: 800px;
}

.award-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-small);
}

.award-logo {
  width: 50px;
  height: 50px;
  margin-right: var(--spacing-medium);
  flex-shrink: 0;
}

.award-info {
  text-align: left;
}

.award-info h3 {
  font-size: var(--font-size-medium);
  color: var(--text-color);
  margin: 0;
}

.award-info p, .award-description {
  font-size: var(--font-size-small);
  color: var(--muted-text-color);
  margin: var(--spacing-xs) 0;
  text-align: left;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .award-header {
    flex-direction: column;
    align-items: center;
  }

  #portfolio {
    padding: var(--spacing-small);
  }

  .award-logo {
    margin-bottom: var(--spacing-small);
  }
}
