#skills {
  padding: var(--spacing-large);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}

@media (min-width: 768px) {
  #skills {
    min-height: 70vh;
  }
}

#skills h1 {
  font-size: var(--font-size-xl);
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: var(--spacing-large);
}

.skill-category h2 {
  font-size: var(--font-size-large);
  color: var(--text-color);
  margin: var(--spacing-medium) 0 var(--spacing-small);
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-medium);
  justify-content: center;
}

.skill-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--secondary-bg-color);
  font-size: var(--font-size-small);
  color: var(--text-color);
  text-align: center;
  box-shadow: var(--shadow-medium);
  padding: var(--spacing-small);
  transition: transform var(--transition-fast) ease;
}

.skill-circle:hover {
  transform: scale(1.2);
}

/* Responsive Adjustments for Mobile Screens */
@media (max-width: 768px) {
  #skills {
    padding: var(--spacing-small);
  }

  .skill-circle {
    width: 70px;
    height: 70px;
    font-size: var(--font-size-xs);
  }
}
