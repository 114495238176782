.section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 0px; */
  min-height: 50vh;
  width: 100%;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  /* box-sizing: border-box; */
  margin-top: 30px; /* Pushes the section down below the header */
}

#home {
  background-color: var(--secondary-bg-color);
}

.responsive-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
